import React from 'react';

export function SpecialContent() {

    return (

        <>
            <main role="main" class="flex-shrink-0" style={{"font-family":"'Raleway', sans-serif"}}>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 text-center">
                        <a href="https://www.batua.eus"><img src="/img/logo_batua.svg" class="mt-4 mx-auto " width="40%" alt="Batua.eus logo" style={{"filter":"brightness(1)","filter":"invert(0.5)"}}/></a>
                    </div>
                    <div class="col-md-6 text-center">
                        <a href="https://www.vicomtech.org"><img src="/img/logo_vicomtech.svg" class="mt-4 mx-auto " width="40%" alt="Vicomtech logo" style={{"filter":"brightness(1)","filter":"invert(0.5)"}}/></a>
                    </div>
                </div>
                <h2 class="mt-5 mb-5 text-center" style={{"color":"#6c757d"}}>Itzulpen-interfaze berezia</h2>

                <form class="mb-4">
                    <div class="form-group row justify-content-md-center">
                        <div class="col-md-8">
                            <div class="btn-group hizkuntza-button-group">
                                <button id="es2eu" type="button" class="btn btn-outline-primary hizkuntza-button active">{"Gaztelania -> Euskara"}</button>
                                <button id="eu2es" type="button" class="btn btn-outline-primary hizkuntza-button">{"Euskara -> Gaztelania"}</button>

                            </div>
                        </div>

                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <textarea class="form-control" id="text_input" rows="12" placeholder="Idatzi edo kopiatu hemen itzuli nahi duzun testua"></textarea>
                        </div>
                        <div class="form-group col-md-12">
                            <textarea class="form-control" id="text_output" rows="12" placeholder="Itzulpena hemen jasoko duzu"></textarea>
                        </div>
                    </div>
                    <div class="form-group row"  style={{"display":"none"}}>
                        <div class="col-sm-12 btn-group" role="group">
                            <a href="#" id="status_key" class="btn btn-secondary disabled" role="button" aria-disabled="true" style={{"font-size":"0.9rem"}}>Abiarazten</a>
                            <a href="#" id="status_wait" class="btn btn-info disabled" role="button" aria-disabled="true" style={{"font-size":"0.9rem"}}>Itxaroten</a>
                            <a href="#" id="status_processing" class="btn btn-warningdisabled" role="button" aria-disabled="true" style={{"font-size":"0.9rem"}}>Prozesatzen</a>
                            <a href="#" id="status_error" class="btn btn-error disabled" role="button" aria-disabled="true" style={{"font-size":"0.9rem"}}>Errorea</a>
                            <a href="#" id="status_processed" class="btn btn-success disabled" role="button" aria-disabled="true" style={{"font-size":"0.9rem"}}>Amaitua</a>
                            
                        </div>
                    </div>
                    <button id="translateButton" type="button" class="btn btn-primary btn-lg btn-block">Itzuli</button>
                    <button id="resetButton" type="button" class="btn btn-secondary btn-lg btn-block">Ezabatu</button>
                    <div class="modal-header text-center pb-4 lege-oharra-area">
                        <p class="w-100">Itzulpen zerbitzu hau erabiltzearekin <a href="../erabilera-baldintzak/" target="_blank">Erabilera-baldintza</a> eta <a href="../pribatutasun-politika/" target="_blank">Pribatutasun-politika</a> onartzen dituzu.</p>
                    </div>
                </form>

            </div>
            </main>

            <footer class="footer mt-auto py-3" style={{"font-family":"'Raleway', sans-serif"}}>
                <div class="container text-center">
                    <span class="" style={{"color":"white"}}>&copy; <a href="https://www.vicomtech.org" target="_blank">Vicomtech</a> :: Powered by Itzuli v2 translation platform :: <a href="/" target="_blank">Batua.eus</a></span>
                </div>
            </footer>
        </>

    )
}