import React from 'react';
import {SpecialContent} from '../components/specialContent';

function Index() {
    return (
        <SpecialContent/>
    );
}
 
export default Index;
